<template>
    <div class="customerreceives-list-page w-100">
        <basic-subheader>
            <template v-slot:filters>
                <b-row>
                    <div>
                        <b-tabs card class="customer-list-page__body__tabs ch__tab">
                            <b-tab active @click="onChangeComponent('CustomerReceive')">
                                <template #title>
                                    <span>Danh sách tiếp nhận</span>
                                </template>
                            </b-tab>
                            <b-tab @click="onChangeComponent('CustomerInProcess')">
                                <template #title>
                                    <span>Danh sách tham gia</span>
                                </template>
                            </b-tab>
                            <b-tab @click="onChangeComponent('CustomerPotential')">
                                <template #title>
                                    <span>Danh sách tiềm năng</span>
                                </template>
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-row>
            </template>
        </basic-subheader>
        <b-overlay>
            <b-container v-if="currentComponent == 'CustomerReceive'" fluid
                class="CustomerReceives-list-page__body mt-6">
                <customer-receive-tab />
            </b-container>
            <b-container v-if="currentComponent == 'CustomerInProcess'" fluid
                class="CustomerReceives-list-page__body mt-6">
                <customer-in-process-tab />
            </b-container>
            <b-container v-if="currentComponent == 'CustomerPotential'" fluid
                class="CustomerReceives-list-page__body mt-6">
                <customer-potential-tab />
            </b-container>
        </b-overlay>
    </div>
</template>

<script>
export default {
    components: {
        'customer-receive-tab': () => import('./components/CustomerReceiveTab.vue'),
        'customer-in-process-tab': () =>
            import('./components/CustomerInProcessTab.vue'),
        'customer-potential-tab': () =>
            import('./components/CustomerPotentialTab.vue'),
    },
    data() {
        return {
            currentComponent: 'CustomerReceive', // Cust
        };
    },
    methods: {
        async onChangeComponent(component = 'CustomerReceive') {
            this.currentComponent = component;
        },
    },
};
</script>
<style lang="scss">
.ch__tab {
    height: 95px;
}
</style>